import {AppState, Auth0Provider, User} from "@auth0/auth0-react";
import React, {FunctionComponent, PropsWithChildren} from "react";
import {RouterManager} from "../worldlies-router-provider";
import {getServiceBroker, ServiceType} from "../services/service-broker-types";
import {IAuthService} from "../services/security-service-types";
import {WorldliesError} from "../models/validation/worldlies-error";

const ENV_VAR_MISSING_MARKER = "ENV-PARAM-VALUE-MISSING"

export const AuthProviderConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || ENV_VAR_MISSING_MARKER,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || ENV_VAR_MISSING_MARKER,
    authorizationParams: {
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL || ENV_VAR_MISSING_MARKER,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || ENV_VAR_MISSING_MARKER,
        useRefreshTokens: true,
        cacheLocation: "localstorage",
        //  onRedirectCallback: redirectRelay,
    }
};

const objHasMissingEnvVars = (obj: object)  => {

    const result = Object.keys(obj).forEach(o => {
        if (o === ENV_VAR_MISSING_MARKER) {
            throw WorldliesError.fromMessageAndContext(`Missing environment variables needed by AuthenticationService`,AuthProviderConfig)
        } else if (typeof o === 'object') {
            objHasMissingEnvVars(o)
        }
    })
}
objHasMissingEnvVars(objHasMissingEnvVars)


interface IAuth0ProviderWithNavigateProps extends PropsWithChildren {
    redirectCallback?: (appState?: AppState, user?: User) => void,
}

export const Auth0ProviderWithNavigate: FunctionComponent<IAuth0ProviderWithNavigateProps> = ({
                                                                                                  children,
                                                                                                  ...rest
                                                                                              }): JSX.Element | null => {

    console.trace("Component: Auth0ProviderWithNavigate");
    // const navigate = useNavigate()


    const redirectRelay = (appState?: AppState, user?: User) => {
        console.debug(`Auth0ProviderWithNavigate::redirectRelay`, null, {
            appState: appState,
            user: user,
        })
        //return (ServiceBroker.getServiceBroker().getService(ServiceType.Authentication) as IAuthService).onRedirectCallback(appState, user)
        if (!appState || !appState.returnTo) {
            const errorPage = RouterManager.getRouterManager().getRouteSpecByName('ErrorPage')
            const msg = `Redirect relay did not recieve and AppState from which it needs the URL to which to redirect, as per provided to Auth0 at the time of logon`
            throw WorldliesError.fromMessageAndContext(msg, {appState: appState, user: user})
        }
        const as = getServiceBroker().getService(ServiceType.Authentication) as IAuthService
        as.onRedirectCallback(appState, user)
    }


    return (
        <Auth0Provider {...AuthProviderConfig}>
            {children}
        </Auth0Provider>
    );
};
