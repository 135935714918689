import {IServiceBase, IServiceBroker, ServiceType} from "./service-broker-types";
import {number} from "yup";


export class ServiceBroker implements IServiceBroker {
    private static _broker = new ServiceBroker()

    private serviceMap = new Map<ServiceType, IServiceBase>();

    public static getServiceBroker() {
        return ServiceBroker._broker as IServiceBroker
    }


    public getService = (serviceType: ServiceType) => {
        return this.serviceMap.get(serviceType);
    };

    public getServiceList = (): IServiceBase[] => {
        return Array.from(this.serviceMap.values())
    }

    public registerService = (service: IServiceBase) => {
        if (service) {
            const type = service.getServiceType();
            if (!this.serviceMap.has(type)) {
                this.serviceMap.set(type, service);
            }

        }

    };

    public registerServiceList = (services: IServiceBase[]) => {
        services.forEach(s => this.registerService(s))
    }

    public getServiceCount = () => {
        return this.serviceMap.size
    }

}







