import React, {MutableRefObject, PropsWithChildren, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import PropTypes from "prop-types";

import dayjs from "dayjs";

import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    MenuItem,
    Stack,
    SxProps,
    Tab,
    Tabs,
    TextField,
    Typography,
    useTheme,
    Zoom
} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';


import {
    Control,
    Controller,
    FieldErrors,
    FormProvider,
    useForm,
    useFormContext,
    useFormState,
    useWatch
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";


import {useWindowSize} from "@react-hook/window-size";


import {IExtendableObjSchema} from "../../utils/extensible-obj-schema";
import {Asset, Category, ClientSession, ErrorResponse, Place} from "../../models/domain/worldlies-data-module";
import {assetFormSchema, IAssetFormData, updateAssetFromForm} from "../../models/validation/asset-validation"
import {createPropertyList, createValueMap} from "../../models/validation/form-validation-utils"
import { createCategory} from "../../models/domain/creators";
import './asset-editor-panel.css';

import {navToErrorPage} from "../../utils/navigation-utils";

import {getCircularReplacer} from "../../utils/json-utils";
import {numberToCurrencyString} from "../../utils/gen-utils";
import {WorldliesError} from "../../models/validation/worldlies-error";
import {createAssetNode} from "../../models/container/asset-node";
import {createPlaceNode} from "../../models/container/place-node";
import {IAuthService} from "../../services/security-service-types";
import {ServiceType, IServiceBase, IServiceBroker, getServiceBroker} from "../../services/service-broker-types";
import {ICategoryService, IPlaceAssetService} from "../../services/data-service-types";





interface IAssetEditorPanelProps extends IExtendableObjSchema {
    open: boolean,
    handleClose: () => void,
    handleSave: (asset: Asset) => void,
    asset?: Asset,
    width: number | string | undefined,
    height: number | string | undefined,

}

interface FieldRef {
    property: string,
    value: any,
    isDirty: false,
}

const FormDataWatch = ({control, obj, onChange}: {
    control: Control<IAssetFormData>,
    obj: Asset,
    onChange: (changeList: Array<FieldRef>) => void,
}) => {

    console.log("Component: FormDataWatch") // TRACE;

    const propArray = createPropertyList(assetFormSchema, obj)
    const propValueArray = createValueMap(assetFormSchema, obj);
    const watches = useWatch({control, name: propArray, defaultValue: propValueArray});
    const methods = useFormContext<IAssetFormData>();
    const {dirtyFields} = useFormState({control: control})


    // const [dirtyFieldList, setDirtyFieldList] = useState<Array<FieldRef>>(new Array<FieldRef>());
    const [changeList, setChangeList] = useState<Array<FieldRef>>(new Array<FieldRef>());

    const strDate = dayjs(new Date()).format('YYYY-MM-DD HH24:mm:ss')
    useEffect(() => {
        if (dirtyFields && watches && methods && changeList) {
            console.debug(`useWatch trigger at ${strDate}:`);
            const tempChangeList: Array<FieldRef> =
                propArray.map((propName, index) => {
                    const propValue = watches[index];

                    const isDirty = (propName in dirtyFields)

                    const result = {
                        property: propName,
                        value: propValue,
                        isDirty: isDirty,
                    } as FieldRef;
                    console.debug(`\t${index} name: '${propName}', value: '${propValue}, change status: ${isDirty ? "DIRTY" : "NOT DIRTY"}`)
                    return result;

                });
            setChangeList(tempChangeList);
            onChange(tempChangeList)
        }
    }, [watches, methods, dirtyFields, changeList]);


    return (
        <Box>
            {/*<h2>WATCH LIST</h2>*/}
            {/*{!changeList || changeList.length == 0 ? <div></div> :*/}
            {/*    <TableContainer>*/}
            {/*        <Table sx={{fontSize: "10pt", color: "darkblue", borderWidth: "1", borderColor: "black"}}>*/}
            {/*            <TableHead>*/}
            {/*                <TableRow key={'HEADING'}>*/}
            {/*                    <TableCell>Property</TableCell><TableCell>Value</TableCell><TableCell>Is*/}
            {/*                    Dirty</TableCell>*/}
            {/*                </TableRow>*/}
            {/*            </TableHead>*/}
            {/*            <TableBody>*/}
            {/*                {changeList.map(e => (*/}
            {/*                    <TableRow key={`PROPERTY-${e.property}`} sx={{borderWidth: "1"}}><TableCell*/}
            {/*                        sx={{borderWidth: "1"}}>{e.property}</TableCell><TableCell*/}
            {/*                        sx={{borderWidth: "1"}}>{e.value}</TableCell><TableCell*/}
            {/*                        sx={{borderWidth: "1"}}>{e.isDirty ? "TRUE" : "FALSE"}</TableCell></TableRow>*/}
            {/*                ))}*/}
            {/*            </TableBody>*/}
            {/*        </Table>*/}
            {/*    </TableContainer>*/}
            {/*}*/}

        </Box>

    )

}

interface TabPanelProps extends PropsWithChildren {
    //  children?: React.ReactNode;
    id: string,
    index: number;
    value: string;
    title: string;
    backgroundColor?: string;
    classes?: string;
    ref?: MutableRefObject<any>,
    sx?: SxProps,
}

function CustomTabPanel(props: TabPanelProps) {

    console.log("Component: CustomTabPanel") // TRACE;
    const {children, value, index, ...other} = props;
    // const isTop = true; // (value == index);
    // const zOrder = (isTop ? 1000 + index : index)


    const theme = useTheme();
    const titleColor = theme.palette.primary.dark;
    const dividerColor = titleColor;
    const bgColor = (props.backgroundColor ? props.backgroundColor : "white")
    return (
        <Box

            role="tabpanel"
            key={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {!props.title ? <></> :
                (
                    <div>
                        <Divider sx={{borderBottomWidth: 2, borderColor: dividerColor}}/>
                        <Typography color={titleColor} align={"left"} variant={"h6"}
                                    gutterBottom={true}>{props.title}</Typography>
                    </div>
                )
            }
            <Box className={props.classes}>
                {children}
            </Box>
        </Box>
    );
}

const TAB_PANEL_NAME_GENERAL = "tab-panel-general"
const TAB_PANEL_NAME_MODEL = "tab-panel-model"
const TAB_PANEL_NAME_PURCHASE = "tab-panel-purchase"
const TAB_PANEL_NAME_SELLING = "tab-panel-selling"
const TAB_PANEL_NAME_INSURANCE = "tab-panel-insurance"

const makeTabId = (panalId: string) => `${panalId}-TAB`

const TAB_PANEL_NAME_MAP = new Map<string, string>(
    [
        [TAB_PANEL_NAME_GENERAL, makeTabId(TAB_PANEL_NAME_GENERAL)],
        [TAB_PANEL_NAME_MODEL, makeTabId(TAB_PANEL_NAME_MODEL)],
        [TAB_PANEL_NAME_PURCHASE, makeTabId(TAB_PANEL_NAME_PURCHASE)],
        [TAB_PANEL_NAME_SELLING, makeTabId(TAB_PANEL_NAME_SELLING)],
        [TAB_PANEL_NAME_INSURANCE, makeTabId(TAB_PANEL_NAME_INSURANCE)],
    ])


export const AssetEditorPanel: React.FC<IAssetEditorPanelProps> = (props: IAssetEditorPanelProps) => {

    console.log("Component: AssetEditorPanel") // TRACE;

    const theme = useTheme();
    const titleColor = theme.palette.primary.dark;
    const dividerColor = theme.palette.primary.main;

    const navigate = useNavigate();

    const [width, height] = useWindowSize();



    const serviceBroker = getServiceBroker()
    const authService = serviceBroker.getService(ServiceType.Authentication) as IAuthService
    const categoryService = serviceBroker.getService(ServiceType.Category) as ICategoryService
    const placeAssetModel = serviceBroker.getService(ServiceType.PlaceAsset) as IPlaceAssetService

    const clientSession = authService.getClientSession() as ClientSession


    const unnamedAsset = "I Have No Name";


    const title = props.asset?.friendlyName || unnamedAsset;

    const createDialogTitle = (assetName: string | undefined) => {
        return (!assetName ? unnamedAsset : assetName);
    }
    // const [assetSchema, setAssetSchema] = useState<ISchema<any> | undefined>(undefined);
    // const [entitySchemaList, setEntitySchemaList] = useState<EntitySchema[] | undefined>(undefined);
    const [internalOpen, setInternalOpen] = useState(false);
    const defaultPlaceOption = [(<MenuItem id={'0'} value={'0'}></MenuItem>)];
    const [places, setPlaces] = useState<Place[]>([]);
    const [asset, setAsset] = useState<Asset | undefined>(undefined);
    const [curPlace, setCurPlace] = useState<Place | undefined>(undefined)
    const [restoreAsset, setRestoreAsset] = useState<Asset | undefined>(undefined);
    const [placeOptions, setPlaceOptions] = useState<JSX.Element[]>(defaultPlaceOption);

    const [worldliesError,setWorldliesError] = useState<WorldliesError>();
    const [placeOpen, setPlaceOpen] = useState(false);

    const [dialogTitle, setDialogTitle] = useState<string>(title);
    const [assetName, setAssetName] = useState('');
    const [placeId, setPlaceId] = useState<string>("");
    const [placeName, setPlaceName] = useState<string>("");

    const [categories, setCategories] = useState<Category[] | undefined>(undefined);
    const [categoryId, setCategoryId] = useState<string>("");
    const [categoryName, setCategoryName] = useState<string>("");

    const [make, setMake] = useState<string>('')
    const [modelName, setModelName] = useState<string>('')
    const [modelNum, setModelNum] = useState<string>('')
    const [serialNum, seSerialNum] = useState<string>('')

    const [purchasedAt, setPurchasedAt] = useState<string | undefined>(undefined);
    const [purchaseDate, setPurchaseDate] = useState<string | undefined>(undefined);
    const [purchasePrice, setPurchasePrice] = useState<string | undefined>(undefined)

    const purchaseRef = useRef(null);
    const scrollingPanelRef = useRef(null);
    const tabsRef = useRef(null)
    const dialogContentRef = useRef(null)


    const [selectedTab, setSelectedTab] = useState<string>(TAB_PANEL_NAME_GENERAL);


    const effectiveAsset = asset || props.asset || createAssetNode();

    const methods = useForm<IAssetFormData>(
        {
            resolver: yupResolver<IAssetFormData>(assetFormSchema),
            mode: "onChange",
            values: createValueMap(assetFormSchema, effectiveAsset),

        }
    );

    const [error, setError] = useState<FieldErrors<IAssetFormData>>();
    const [failedProperties, setFailedProperties] = useState<Array<{ property: string, message: string }>>();


    let dirtyFieldList: Array<string> = [];



    useEffect(() => {
        if (!categories) {

            const cats = categoryService.getAllForScope("ASSET");
            if (cats) {
                setCategories(cats);
            }
        }


    }, [categories])

    useEffect(() => {
        if (props.open && clientSession && placeAssetModel && !asset && categories) {
            placeAssetModel.load(false)
                .then(() => {
                    let pp = placeAssetModel.getPlaces();
                    pp = [...pp].sort((p1, p2) => {
                        let compare = p1.friendlyName.localeCompare(p2.friendlyName);
                        if (compare == 0 && p1.sortOrder && p2.sortOrder) {
                            compare = p1.sortOrder - p2.sortOrder;
                        }
                        if (compare == 0) {
                            compare = p1.id.localeCompare(p2.id);
                        }
                        return compare;
                    });
                    setPlaces(pp);
                })

            let addMode = !props.asset?.friendlyName;
            let aa: Asset = createAssetNode();
            if (!addMode) {
                aa = props.asset as Asset;
            }
            let pId = aa.placeId;
            let title = createDialogTitle(aa.friendlyName)
            let p: Place | undefined;


            setAsset(aa);
            setPlaceId(pId)

            setAssetName(aa.friendlyName);
            setDialogTitle(title);


            if (props.asset && props.asset.id == aa.id) {
                setRestoreAsset(aa);
            }
            if (aa.placeId) {
                setPlaceId(aa.placeId);

            }


            setInternalOpen(true)
        } else {
            setInternalOpen(true)
        }

    }, [props.open, clientSession, placeAssetModel, asset, categories]);

    useEffect(() => {
        if (places && placeId && placeAssetModel) {
            const p = placeAssetModel.getPlaceById(placeId) as Place;
            setCurPlace(p);
            setPlaceName(p?.friendlyName);
        }
    }, [places, placeId, placeAssetModel]);


    useEffect(() => {
        if (worldliesError) {
            worldliesError.debugMessage = (worldliesError.debugMessage ? worldliesError.debugMessage : "") || ` - Component: AssetEditorPanel`;
             navToErrorPage(navigate, worldliesError,"AssetEditorPanel")
        }

    }, [worldliesError]);


    useEffect(() => {
        if (methods.formState.dirtyFields && typeof methods.formState.dirtyFields === 'object') {
            for (let p in methods.formState.dirtyFields) {
                dirtyFieldList.push(p);
            }
        }
    }, [methods.formState.isDirty]);


    useEffect(() => {
        if (methods.formState.isSubmitting) {
            console.log(`in Is Submitting`)
        }
    }, [methods.formState.isSubmitting]);

    useEffect(() => {
        if (methods.formState.isSubmitted && !methods.formState.isSubmitSuccessful) {
            const failedProperties = new Array<{ property: string, message: string }>();
            for (let property in methods.formState.errors) {
                const errObj = {
                    property: property,
                    message: (methods.formState.errors as IExtendableObjSchema)[property].message,
                }

                failedProperties.push(errObj);
            }
            console.debug(`Unsucessful submittal due to following:`)
            failedProperties.forEach((value, index) => {
                console.debug(`(${index}) ${value.property}: ${value.message}`)
            })
            setError(methods.formState.errors)
            setFailedProperties(failedProperties);
            setTimeout(() => {
                setFailedProperties(undefined);
                setError(undefined);
            }, 5 * 1000)

        }
    }, [methods.formState.isSubmitted, methods.formState.isSubmitSuccessful]);

    // useEffect(() => {
    //     if (dialogContentRef.current) {
    //         (dialogContentRef.current as HTMLElement).addEventListener('focus',onTabSelectionOnComponentFocus)
    //     }
    // }, [dialogContentRef, dialogContentRef.current]);

    const updateDialogTitle = (newTitle: string) => {
        const isValid = !methods.getFieldState('friendlyName').invalid;
        if (isValid) {
            setDialogTitle(newTitle);
        } else {
            setDialogTitle(title);
        }
    }

    const onNameChange = (newName: string) => {
        updateDialogTitle(newName);
        setAssetName(newName);
    }

    const onPlaceChange = (newPlaceId: string) => {
        if (newPlaceId == "ADD") {
            // do popup add new place
        }
    }

    const onCategoryChange = (newCategoryId: string) => {
        if (newCategoryId == "ADD") {
            // do popup add new place
        }
    }

    const onTestChange = (source: string, data: {
        event: any,
        value: any,
        reason: string
    }, eventHandler?: (newVal: any) => void): void => {
        console.log(`onTestChange source: ${source} data\n: ${JSON.stringify(data, getCircularReplacer, 2)}\n\n`)
        let deducedValue = data.value
        if (source === 'categoryId-onChange') {
            if (typeof data.value === 'object') {
                deducedValue = (data.value as Category).id
            } else {
                deducedValue = data.value as string
            }
            console.debug(`updating asset categoryId to ${deducedValue}`)
        }
        if (source === 'purchasePrice.onChange') {
            const value = data.value as string
            const cleanValue = value.replace(/[^\d.-]/g, "")
            const num = Number.parseFloat(cleanValue)
            console.debug(`updating asset categoryId to ${num}`)
        }

    }


    const onCloseButton = () => {
        props.handleClose();

    }


    const onDialogClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick') {
            // do nothing
            props.handleClose();
        } else {
            props.handleClose();
        }

    }

    const onChange = (changeList: Array<FieldRef>) => {
        console.log(`Got notification of the following changes:`)
        changeList.forEach(e => {
            console.log(`'${e.property}' = ${e.value}`)
            if (e.property === 'friendlyName') {
                updateDialogTitle(e.value as string)
                setAssetName(e.value as string)
            }
        })
    }


    const saveAsset = () => {


        let updatedAsset = {
            ...asset,
            accountId: clientSession?.accountId,
        } as Asset;
        updatedAsset = updateAssetFromForm(methods.getValues, updatedAsset);
        console.debug(`Saving asset\n${JSON.stringify(updatedAsset, null, 2)}`)
        props.handleSave(updatedAsset as Asset);

    }

    const onTabSelection = (event: React.SyntheticEvent, newValue: string) => {
        const tabValue = newValue
        const currentTarget = event.currentTarget
        const tabEl = event.target
        const tabIndex = (tabEl ? (tabEl as IExtendableObjSchema)["tabIndex"] : null)

        if (tabIndex) {
            setSelectedTab(tabIndex);
        } else {
            console.error(`AssetPanel.onTabSelection: Unable to retrieve selected tab's index through the ref`)
        }
        if (tabValue) {
            const el = document.getElementById(tabValue)
            if (!el) {
                throw WorldliesError.fromMessage(`The tab value of ${tabValue} does not correspond to a DOM element ID to which we can scroll`)
            }
            el.scrollIntoView();
        } else {
            console.error(`AssetPanel.onTabSelection: called without a value parameter`)
        }
    }

    const onTabSelectionOnComponentFocus = (event: React.FocusEvent<HTMLDivElement>) => {


        let el: HTMLElement | null | undefined = event.target as HTMLElement
        while (el) {
            if (el.id == 'AssetEditor-Content') {
                break;
            }
            if (TAB_PANEL_NAME_MAP.has(el.id)) {
                setSelectedTab(el.id)
                break;
            }
            el = el.parentElement
        }
    }


    const okToRender = (categories && categories.length > 0 && places && places.length > 0) || false; //  scrollingPanelRef && scrollingPanelRef.current && pur`chaseRef && purchaseRef.current) || false;
    console.log(`okToRender: ${okToRender}`)

    const getPlacesForOptions = () => {

        if (!places || places.length == 0) {
            return [];
        }
        let pp: Place[] = [];
        const p = createPlaceNode();
        pp.push(p);
        pp[0].friendlyName = "Add new category...";
        pp[0].id = "ADD";
        pp = pp.concat(places);
        return pp;
    }

    const getCategoriesForOptions = () => {
        if (!!!categories || categories.length == 0) {
            return [];
        }
        let cc: Category[] = [];
        const c = createCategory();
        cc.push(c);
        cc[0].friendlyName = "Add new category...";
        cc[0].id = "ADD";
        cc = cc.concat(categories)
        return cc;
    }



    const INTER_FIELD_SPACING = "40px"
    return (

        ((!okToRender)
                ?
                <div></div>
                :
                <Dialog

                    // disablePortal
                    id={'AssetEditor-dialog'}
                    open={internalOpen}
                    onClose={onDialogClose}
                    scroll={'paper'}
                    fullWidth={true}
                    maxWidth={'lg'}
                    disableEscapeKeyDown={true}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    PaperProps={{sx: {width: width * 0.9, height: height * 0.9, padding: "30px", borderRadius: 3}}}>


                    <DialogTitle sx={{color: titleColor, textShadow: `1px 1px lightgray`}}>
                        <Stack direction={"column"}>
                            <Box>{`Asset: ${dialogTitle}`}</Box>
                            {/*<Divider sx={{borderBottomWidth: 5, borderColor: dividerColor}}/>*/}
                            <Stack id={"asset-editor-header-panel"} direction={"column"}>
                                <Tabs value={selectedTab}
                                      onChange={onTabSelection}
                                      indicatorColor="primary"
                                      textColor="inherit"
                                      variant="fullWidth"
                                      ref={tabsRef}

                                >

                                    <Tab id={makeTabId(TAB_PANEL_NAME_GENERAL)} value={TAB_PANEL_NAME_GENERAL}
                                         label="General" className={"asset-general-tab"}/>
                                    <Tab id={makeTabId(TAB_PANEL_NAME_MODEL)} value={TAB_PANEL_NAME_MODEL}
                                         label="Model-Specification"
                                         className={"asset-model-tab"}/>
                                    <Tab id={makeTabId(TAB_PANEL_NAME_PURCHASE)} value={TAB_PANEL_NAME_PURCHASE}
                                         label="Purchasing" className={"asset-purchase-tab"}/>
                                    <Tab id={makeTabId(TAB_PANEL_NAME_SELLING)} value={TAB_PANEL_NAME_SELLING}
                                         label="Selling" className={"asset-selling-tab"}/>
                                    <Tab id={makeTabId(TAB_PANEL_NAME_INSURANCE)} value={TAB_PANEL_NAME_INSURANCE}
                                         label="Insurance" className={"asset-insurance-tab"}/>

                                </Tabs>
                                <Zoom in={!!failedProperties}
                                      style={{transitionDelay: !!failedProperties ? '500ms' : '0ms'}}>
                                    {(!!failedProperties) ?
                                        <Alert variant={'filled'} severity={'error'}>
                                            <Box>
                                                <ul>
                                                    {failedProperties.map((value: {
                                                            property: string,
                                                            message: string
                                                        }, index: number) => (
                                                            <li>{`(${index}) ${value.property} - ${value.message}`}</li>)
                                                    )}
                                                </ul>
                                            </Box>
                                        </Alert>
                                        : <div></div>}
                                </Zoom>
                            </Stack>
                        </Stack>
                    </DialogTitle>
                    <DialogContent id={'AssetEditor-Content'}
                                   ref={dialogContentRef}
                                   onFocus={onTabSelectionOnComponentFocus}
                                   sx={{
                                       marginTop: "5px",
                                       height: "100%",
                                       borderColor: dividerColor,
                                       borderWidth: 3,
                                       borderStyle: "solid",
                                       borderRadius: 3,

                                       boxShadow: 4,
                                   }}

                    >
                        <Stack id={"asset-editor-content-inner-container"} direction={"column"}

                               ref={scrollingPanelRef}

                        >
                            <FormProvider {...methods} >
                                <form id={"asset-editor-form"} onSubmit={methods.handleSubmit(saveAsset)}>
                                    <Stack direction={'column'} spacing={INTER_FIELD_SPACING}>
                                        <CustomTabPanel
                                            id={TAB_PANEL_NAME_GENERAL}
                                            value={selectedTab}
                                            index={1}
                                            classes="asset-general-panel"
                                            sx={{borderWidth: 2}}
                                            title={"General"}

                                        >
                                            <Stack key={"asset-general-panel-stack"}
                                                   id={"asset-general-panel-stack"}
                                                   direction={'row'}
                                                   spacing={INTER_FIELD_SPACING}

                                            >


                                                <Controller
                                                    name={"friendlyName"}
                                                    control={methods.control}
                                                    defaultValue={assetName || ''}
                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,
                                                        }
                                                    ) => (
                                                        <TextField
                                                            {...field}
                                                            id="AssetName"
                                                            key={"AssetName"}
                                                            label={"Name"}
                                                            inputRef={ref}
                                                            variant={'outlined'}
                                                            focused={true}
                                                            value={value || assetName}
                                                            onChange={onChange}
                                                            sx={{width: 500, marginTop: 5}}
                                                            error={!!methods.formState.errors.friendlyName}
                                                            aria-invalid={!!methods.formState.errors.friendlyName}
                                                            helperText={!!methods.formState.errors.friendlyName ? `${methods.formState.errors.friendlyName.message}` : 'Provide a name for the asset'}
                                                            FormHelperTextProps={!!methods.formState.errors.friendlyName ? {
                                                                sx: {
                                                                    fontSize: "medium",
                                                                    fontWeight: "bold",
                                                                    color: "red"
                                                                }
                                                            } : {}}
                                                            placeholder={'Provide a name for this item'}


                                                        />
                                                    )}/>

                                                <Controller
                                                    name={"placeId"}
                                                    control={methods.control}

                                                    defaultValue={
                                                        (places.map(p => p.id).includes(placeId) ? placeId : " ")}
                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,
                                                        }
                                                    ) => (

                                                        <TextField
                                                            //  {...field}
                                                            select
                                                            id="place-select"
                                                            // key={"PlaceSelect"}
                                                            inputRef={ref}
                                                            label={'Location'}
                                                            variant={'outlined'}
                                                            sx={{width: 500, marginTop: 5}}
                                                            value={value}
                                                            onChange={onChange}
                                                            aria-invalid={!!methods.formState.errors.placeId}
                                                            helperText={methods.formState.errors.placeId ? `${methods.formState.errors.placeId.message}` : "Select the asset's location"}
                                                            FormHelperTextProps={methods.formState.errors.placeId ? {
                                                                sx: {
                                                                    fontSize: "medium",
                                                                    fontWeight: "bold",
                                                                    color: "red"
                                                                }
                                                            } : {}}


                                                        >
                                                            <MenuItem key={' '} value={' '}
                                                                      selected={!!!placeId}></MenuItem>
                                                            {getPlacesForOptions().map(o => {
                                                                const sel = o.id == value;
                                                                return <MenuItem key={o.id} value={o.id}
                                                                                 selected={sel}>{o.friendlyName}</MenuItem>
                                                            })}
                                                        </TextField
                                                        >
                                                    )}

                                                />
                                                {/*</Tooltip>*/}
                                            </Stack>
                                        </CustomTabPanel>
                                        <CustomTabPanel
                                            id={TAB_PANEL_NAME_MODEL}
                                            value={selectedTab}
                                            index={2}
                                            classes="asset-model-panel"
                                            title={"Model Information"}
                                        >

                                            <Stack key={"asset-model-panel-stack"}
                                                   id={"asset-model-panel-stack"}
                                                   direction={'column'}
                                                   spacing={INTER_FIELD_SPACING}>
                                                {/*PANEL 2*/}
                                                <Controller
                                                    name={"make"}
                                                    control={methods.control}


                                                    defaultValue={make || ''}
                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,


                                                        }
                                                    ) => (
                                                        <TextField
                                                            {...field}
                                                            id="Make"
                                                            key={"Make"}
                                                            label={"Make/Manufacturer"}
                                                            inputRef={ref}
                                                            variant={'outlined'}
                                                            value={value || make}
                                                            onChange={onChange}
                                                            sx={{width: 500, marginTop: 5}}
                                                            error={!!methods.formState.errors.make}
                                                            aria-invalid={!!methods.formState.errors.make}
                                                            helperText={methods.formState.errors.make ? `${methods.formState.errors.make.message}` : 'Who made this item?'}
                                                            FormHelperTextProps={methods.formState.errors.make ? {
                                                                sx: {
                                                                    fontSize: "medium",
                                                                    fontWeight: "bold",
                                                                    color: "red"
                                                                }
                                                            } : {}}


                                                        />
                                                    )}

                                                />
                                                <Stack direction={"row"} spacing={INTER_FIELD_SPACING}>
                                                    <Controller
                                                        name={"modelName"}
                                                        control={methods.control}

                                                        defaultValue={modelName || ''}
                                                        render={(
                                                            {
                                                                field: {ref, onChange, value, ...field},
                                                                fieldState: {error, invalid},
                                                                formState,


                                                            }
                                                        ) => (
                                                            <TextField
                                                                {...field}
                                                                id="modelName"
                                                                key={"modelName"}
                                                                label={"Model name"}
                                                                inputRef={ref}
                                                                variant={'outlined'}
                                                                value={value || make}
                                                                onChange={onChange}
                                                                sx={{width: 500, marginTop: 5}}
                                                                error={!!methods.formState.errors.modelName}
                                                                aria-invalid={!!methods.formState.errors.modelName}
                                                                helperText={methods.formState.errors.modelName ? `${methods.formState.errors.modelName.message}` : 'What is the model name of this item'}
                                                                FormHelperTextProps={methods.formState.errors.modelName ? {
                                                                    sx: {
                                                                        fontSize: "medium",
                                                                        fontWeight: "bold",
                                                                        color: "red"
                                                                    }
                                                                } : {}}


                                                            />
                                                        )}

                                                    />

                                                    <Controller
                                                        name={"modelNum"}
                                                        control={methods.control}

                                                        defaultValue={modelNum || ''}
                                                        render={(
                                                            {
                                                                field: {ref, onChange, value, ...field},
                                                                fieldState: {error, invalid},
                                                                formState,


                                                            }
                                                        ) => (
                                                            <TextField
                                                                {...field}
                                                                id="modelNum"
                                                                key={"modelNum"}
                                                                label={"Model number"}
                                                                inputRef={ref}
                                                                variant={'outlined'}
                                                                value={value || make}
                                                                onChange={onChange}
                                                                sx={{width: 500, marginTop: 5}}
                                                                error={!!methods.formState.errors.modelNum}
                                                                aria-invalid={!!methods.formState.errors.modelNum}
                                                                helperText={methods.formState.errors.modelNum ? `${methods.formState.errors.modelNum.message}` : "What is manufacturer's model number"}
                                                                FormHelperTextProps={methods.formState.errors.modelNum ? {
                                                                    sx: {
                                                                        fontSize: "medium",
                                                                        fontWeight: "bold",
                                                                        color: "red"
                                                                    }
                                                                } : {}}


                                                            />
                                                        )}

                                                    />

                                                </Stack>
                                                <Controller
                                                    name={"serialNum"}
                                                    control={methods.control}

                                                    defaultValue={serialNum || ''}
                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,


                                                        }
                                                    ) => (
                                                        <TextField
                                                            {...field}
                                                            id="serialNum"
                                                            key={"serialNum"}
                                                            label={"Serial number"}
                                                            inputRef={ref}
                                                            variant={'outlined'}
                                                            value={value || make}
                                                            onChange={onChange}
                                                            sx={{width: 500, marginTop: 5}}
                                                            error={!!methods.formState.errors.serialNum}
                                                            aria-invalid={!!methods.formState.errors.serialNum}
                                                            helperText={methods.formState.errors.serialNum ? `${methods.formState.errors.serialNum.message}` : "What is serial number of the item"}
                                                            FormHelperTextProps={methods.formState.errors.serialNum ? {
                                                                sx: {
                                                                    fontSize: "medium",
                                                                    fontWeight: "bold",
                                                                    color: "red"
                                                                }
                                                            } : {}}


                                                        />
                                                    )}

                                                />
                                            </Stack>
                                        </CustomTabPanel>
                                        <CustomTabPanel
                                            id={TAB_PANEL_NAME_PURCHASE}
                                            value={selectedTab}
                                            index={3}
                                            classes="asset-purchase-panel"
                                            title={"Purchasing"}
                                        >

                                            <Stack direction={'row'} spacing={INTER_FIELD_SPACING}>
                                                <Controller
                                                    name={"purchasedAt"}
                                                    control={methods.control}

                                                    defaultValue={purchasedAt || ''}
                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,


                                                        }
                                                    ) => (
                                                        <TextField
                                                            {...field}

                                                            id="purchasedAt"
                                                            key={"purchasedAt"}
                                                            label={"Purchased from"}
                                                            inputRef={ref}
                                                            variant={'outlined'}
                                                            value={value || make}
                                                            onChange={onChange}
                                                            sx={{width: 500, marginTop: 5}}
                                                            error={!!methods.formState.errors.purchasedAt}
                                                            aria-invalid={!!methods.formState.errors.purchasedAt}
                                                            helperText={methods.formState.errors.purchasedAt ? `${methods.formState.errors.purchasedAt.message}` : "From whom was this item purchased?"}
                                                            FormHelperTextProps={methods.formState.errors.purchasedAt ? {
                                                                sx: {
                                                                    fontSize: "medium",
                                                                    fontWeight: "bold",
                                                                    color: "red"
                                                                }
                                                            } : {}}


                                                        />
                                                    )}

                                                />


                                                <Controller
                                                    control={methods.control}
                                                    name={"purchaseDate"}

                                                    render={({
                                                                 field: {ref, onChange, value, ...field},
                                                                 fieldState: {error, invalid},
                                                                 formState,
                                                             }) => (

                                                        <DatePicker
                                                            sx={{width: 500, marginTop: 5}}
                                                            label="Purchase date"
                                                            value={value ? dayjs(value) : null}
                                                            inputRef={ref}
                                                            onChange={(value) => {
                                                                console.log(`The date value is ${value}`)
                                                            }}
                                                            slotProps={{
                                                                actionBar: {
                                                                    actions: ["today", "accept"],
                                                                },
                                                                textField: {
                                                                    helperText: (!!methods.formState.errors.purchaseDate) ? methods.formState.errors.purchaseDate.message!.toString() : "When did you buy it?",
                                                                    FormHelperTextProps: (!!methods.formState.errors.purchaseDate) ?
                                                                        {
                                                                            sx: {
                                                                                fontSize: "medium",
                                                                                fontWeight: "bold",
                                                                                color: "red"
                                                                            }
                                                                        }
                                                                        : {},
                                                                }
                                                            }}
                                                        />

                                                    )}
                                                />

                                                {/*</DemoContainer>*/}

                                                <Controller
                                                    name={"purchasePrice"}
                                                    control={methods.control}
                                                    defaultValue={(asset?.purchasePrice ?? 0).toFixed(2)}

                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,
                                                        }
                                                    ) => (
                                                        // <MaskedInput
                                                        //     mask={numberMask}
                                                        //     id={"purchase-price-mask"}
                                                        //     showMask={true}
                                                        //     inputMode={"numeric"}
                                                        //     value={numberToCurrencyString(value || "0.00")}
                                                        //
                                                        //     render={(ref, props) => (
                                                                <TextField
                                                                    {...props}
                                                                    {...field}


                                                                    // id="purchasePrice"
                                                                    key={"purchasePrice"}
                                                                    label={"Purchase price"}

                                                                    defaultValue={numberToCurrencyString(value ?? "0.00")}
                                                                    inputRef={ref}
                                                                    variant={'outlined'}

                                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                                        onTestChange('purchasePrice.onChange', {
                                                                            event: event,
                                                                            value: event.currentTarget.value,
                                                                            reason: `Event type ${event.type} on purchasePrice TextField`
                                                                        })
                                                                    }}

                                                                    sx={{width: 500, marginTop: 5}}
                                                                    error={!!methods.formState.errors.purchasePrice}
                                                                    aria-invalid={!!methods.formState.errors.purchasePrice}
                                                                    helperText={methods.formState.errors.purchasePrice ? `${methods.formState.errors.purchasePrice.message}` : "What was the dollar amount of the purchase"}
                                                                    FormHelperTextProps={methods.formState.errors.purchasePrice ? {
                                                                        sx: {
                                                                            fontSize: "medium",
                                                                            fontWeight: "bold",
                                                                            color: "red"
                                                                        }
                                                                    } : {}}


                                                                />
                                                    // )}
                                                    //     />
                                                )}

                                                />
                                            </Stack>
                                        </CustomTabPanel>
                                        <CustomTabPanel
                                            id={TAB_PANEL_NAME_SELLING}
                                            value={selectedTab}
                                            index={4}
                                            classes="asset-selling-panel"
                                            title={"Selling"}>

                                            <Stack direction={'row'} spacing={INTER_FIELD_SPACING}>
                                                <Controller
                                                    name={"categoryId"}
                                                    control={methods.control}

                                                    defaultValue={
                                                        (categories!.map(p => p.id).includes(categoryId) ? categoryId : " ")}
                                                    render={(
                                                        {
                                                            field: {ref, onChange, value, ...field},
                                                            fieldState: {error, invalid},
                                                            formState,
                                                        }
                                                    ) => (
                                                        <Autocomplete

                                                            autoHighlight
                                                            onChange={(event, value, reason) => onChange((value && typeof value === 'object') ? (value as Category).id : value)} // {(event,value,reason) => {onTestChange('categoryId-onChange',{event: event,value: value, reason: reason},onChange)}}
                                                            // onInputChange={(event,value,reason) => {onTestChange('categoryId-onInputChange',{event: event,value: value, reason: reason},onChange)}}
                                                            options={getCategoriesForOptions() as Category[]}
                                                            defaultValue={(categoryService && value && typeof value === 'string') ? categoryService.getById(value as string) || null : null}
                                                            getOptionKey={(option) => option.id}
                                                            getOptionLabel={(option) => option.friendlyName}
                                                            renderOption={(props, option) => (
                                                                <Box component="li"
                                                                     sx={{'& > img': {mr: 2}}} {...props}>

                                                                    {option.friendlyName}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    id={"categoryId"}
                                                                    sx={{width: 500, marginTop: 5}}
                                                                    label="Choose a category"

                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                                    }}
                                                                    helperText={methods.formState.errors.categoryId ? methods.formState.errors.categoryId.message : "Assign a category or create a new one"}
                                                                    FormHelperTextProps={methods.formState.errors.categoryId ? {
                                                                        sx: {
                                                                            fontSize: "medium",
                                                                            fontWeight: "bold",
                                                                            color: "red",
                                                                        }
                                                                    } : {}}
                                                                />
                                                            )}

                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </CustomTabPanel>
                                        <CustomTabPanel
                                            id={TAB_PANEL_NAME_INSURANCE}
                                            value={selectedTab}
                                            index={5}
                                            classes="asset-insurance-panel"
                                            title={"Insurance"}
                                        >
                                            <Stack direction="row" spacing={INTER_FIELD_SPACING}>
                                                <span id={"insurance"}>INSURANCE</span>
                                            </Stack>

                                        </CustomTabPanel>

                                        <FormDataWatch control={methods.control} obj={asset || createAssetNode()}
                                                       onChange={onChange}/>
                                    </Stack>
                                </form>
                            </FormProvider>
                        </Stack>

                        {/*    </Stack>*/}

                        {/*</Stack>*/}

                    </DialogContent>
                    <DialogActions sx={{marginTop: "10px"}}>
                        <Stack id={"asset-editor-action-panel"} direction={'column'}>
                            {/*<Stack id={"asset-editor-nav-button-subpanel"} direction={'row'}*/}
                            {/*       spacing={INTER_FIELD_SPACING}
                                {/*       justifySelf={'right'}*/}
                            {/*       justifyContent={'right'}*/}
                            {/*       sx={{rightMargin: 0, width: "100%"}}>*/}
                            {/*    <IconButton color='primary' id={'tab-home-button'} sx={{width: 60}}*/}
                            {/*                aria-label={"First"}*/}
                            {/*                disabled={selectedTab == 1}*/}
                            {/*                onClick={onTabHome}><FirstPageOutlinedIcon fontSize="large"*/}
                            {/*                                                           fontWeight={"bold"}/></IconButton>*/}
                            {/*    <IconButton color='primary' id={'tab-left-button'} sx={{width: 60}}*/}
                            {/*                aria-label={"Previous"}*/}
                            {/*                disabled={selectedTab == 1}*/}
                            {/*                onClick={onTabLeft}><NavigateBeforeOutlinedIcon fontSize="large"*/}
                            {/*                                                                fontWeight={"bold"}/></IconButton>*/}
                            {/*    <IconButton color='primary' id={'tab-right-button'} sx={{width: 60}}*/}
                            {/*                aria-label={"Next"}*/}
                            {/*                disabled={selectedTab == 5}*/}
                            {/*                onClick={onTabRight}><NavigateNextOutlinedIcon fontSize="large"*/}
                            {/*                                                               fontWeight={"bold"}/></IconButton>*/}
                            {/*    <IconButton color='primary' id={'tab-end-button'} sx={{width: 60}}*/}
                            {/*                aria-label={"Last"}*/}
                            {/*                disabled={selectedTab == 5}*/}
                            {/*                onClick={onTabEnd}><LastPageOutlinedIcon fontSize="large"*/}
                            {/*                                                         fontWeight={"bold"}/></IconButton>*/}
                            {/*</Stack>*/}

                            {/*<Divider sx={{borderBottomWidth: 5, borderColor: dividerColor, bottomMargin: 6}}/>*/}


                            <Stack id={"asset-editor-action-button-subpanel"} direction={'row'}
                                   spacing={INTER_FIELD_SPACING}
                                   justifyContent={'right'}
                                   sx={{rightMargin: 0, width: "100%", marginTop: "10px"}}>

                                <Button id={'cancel-button'} type={'button'} variant={"contained"}
                                        sx={{width: 60}}

                                        onClick={onCloseButton}>Cancel</Button>
                                <Button id={'save-button'} type={'submit'} variant={"contained"}
                                        sx={{width: 60}}
                                        onClick={methods.handleSubmit(saveAsset)}
                                        disabled={!!!(methods.formState.isDirty)}
                                >Save</Button>
                            </Stack>

                        </Stack>
                    </DialogActions>
                </Dialog>
        ));


}
