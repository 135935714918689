import {AppState, Auth0ContextInterface, RedirectLoginOptions, User} from "@auth0/auth0-react";

import {
    AccountCreateResponse,
    ClientAuth0Context,
    ClientSession,
    ErrorResponse, UserLoginRequest,
    VerifyEmailRequest
} from "../../models/domain/worldlies-data-module";
import {createAccountCreateRequest, createClientSession} from "../../models/domain/creators";
import {ISecurityService} from "../api-service-types";
import {WorldliesError} from "../../models/validation/worldlies-error";
import {AsyncConfig} from "../../utils/async-config";
import {IAuthService} from "../security-service-types";
import {IServiceBroker, ServiceType} from "../service-broker-types";

import {ErrorUtils} from "../../utils/error-utils";
import {UuidUtils} from "../../utils/uuid-utils";
import {NavigateFunction} from "react-router-dom";
import {IExtendableObjSchema} from "../../utils/extensible-obj-schema";
import {createLocalAppURL, RouteName, RouterManager, RouteSpec} from "../../worldlies-router-provider";
import {AuthProviderConfig} from "../../providers/auth0-provider-with-navigate";
import {ClientSessionState} from "../../providers/service-init-provider";
import _remove from 'lodash/remove';
import dayjs from 'dayjs'

const CryptoJS = require('crypto-ts');




interface LogonStateSave {
    timestamp: Date,
    providerId: string,
    originalString: string,
    encryptedString: string,
    appState: AppState,
    user: User,
    redirectUri: string,
}

const MISSING_STATE_CACHE_SECONDS = -999

let temp = process.env.REACT_APP_MAX_AUTHENTICATE_STATE_CACHE_SECONDS
const MAX_AUTHENTICATE_STATE_CACHE_SECONDS= (typeof process.env.REACT_APP_MAX_AUTHENTICATE_STATE_CACHE_SECONDS === 'number')  ? Number(process.env.REACT_APP_MAX_AUTHENTICATE_STATE_CACHE_SECONDS) : MISSING_STATE_CACHE_SECONDS

if (MAX_AUTHENTICATE_STATE_CACHE_SECONDS === MISSING_STATE_CACHE_SECONDS) {
    throw WorldliesError.fromMessage(`REACT_APP_MAX_AUTHENTICATE_STATE_CACHE_SECOND is missing or is not a number: '${process.env.REACT_APP_MAX_AUTHENTICATE_STATE_CACHE_SECONDS}'`)
}

export class AuthService extends  IAuthService {

    private navigate : NavigateFunction | undefined = undefined;
    private authContext: ClientAuth0Context | null = null;
    private securityService: ISecurityService ;
    private auth0: Auth0ContextInterface<User>;
    private user: User | undefined = undefined;
    private clientSessionContext : ClientSessionState;
    private authToken: string | undefined = undefined;
    private needPasswordChange : boolean | undefined = undefined;
    private emailVerified : boolean | undefined = undefined;
    private accountExists : boolean | undefined = undefined
    private clientSession: ClientSession | undefined = undefined;
    private abortController = new AbortController();
    private providerStateMap = new Map<string,Map<string,string>>();

    private auth0StateString =  "I wish my brother George was here";
    private secretKey = "I wish my brother George was here";

    private auth0LogonStateMap = new Map<string,LogonStateSave>


    constructor(serviceBroker: IServiceBroker, auth0: Auth0ContextInterface<User>, clientSessionContext: ClientSessionState, navigate?: NavigateFunction) {
        super(ServiceType.Authentication);
        this.securityService = serviceBroker.getService(ServiceType.Security) as ISecurityService
        this.auth0 = auth0
        this.clientSessionContext = clientSessionContext
        this.navigate = navigate ?? undefined
        console.log("Component: AuthService") // TRACE;
    }

    setNavigationFunction(navigateFunc: NavigateFunction) {
        this.navigate = navigateFunc
    }

    private  processPreLogin (logonOptions: RedirectLoginOptions<AppState>) :  RedirectLoginOptions<AppState> {
        if (!logonOptions.appState) {
            throw WorldliesError.fromMessageAndContext(`processPreLogin recieved a RedirectLoginOptions object that did not have the recquied AppState property`,{loginOptions: logonOptions})
        }
        const appState = logonOptions.appState!
        const logonLandingPageSpec = RouterManager.getRouterManager().getRouteSpecByName(RouteName.LoginLandingPage) as RouteSpec
        const url = createLocalAppURL(appState.returnTo ?? (logonLandingPageSpec.contextRelativePath as string)) as string;
        const state = UuidUtils.gen()
        const encrState =  CryptoJS.AES.encrypt(state,this.secretKey).toLocaleString()
        const decrypted = CryptoJS.AES.decrypt(encrState,this.secretKey).toLocaleString()
        const logonState = {
            timestamp: new Date(),
            providerId: "GENERIC",
            originalString: state,
            encryptedString:  encrState,
            appState: logonOptions.appState,
            user: this.user,
            redirectUri: url,
        } as LogonStateSave
        this.auth0LogonStateMap.set(state,logonState)
        appState.returnTo = url
        appState['state'] = encrState
        return logonOptions
    }

    private async doMaintenance() {
        // clear out orphaned state caches
        const cutOffTime = dayjs(new Date()).subtract(MAX_AUTHENTICATE_STATE_CACHE_SECONDS,'seconds')
            Array.from(this.auth0LogonStateMap.values()).filter(e =>  !cutOffTime.isAfter(e.timestamp)).map(e => e.originalString).forEach(s =>{
                this.auth0LogonStateMap.delete(s)
            })
        // what else?
    }

    private processRedirection(appState: AppState, user?: User) {
        const encodedState = appState['state']
        if (!encodedState) {
            throw WorldliesError.fromMessageAndContext(`Auth0 logon redirect callback was presented with an AppState that did not contain our 'state' value`, {appState: appState, user: user})
        }
        const encrState =  atob(encodedState)
        if (!encrState) {
            throw WorldliesError.fromMessageAndContext(`Auth0 logon redirect callback was presented with an AppState from which it could not retrieve a 'state' value that Base64-decoded`, {appState: appState, user: user})

        }
        const state = CryptoJS.AES.decrypt(encrState,this.secretKey).toLocaleString()
        if (!state) {
            throw WorldliesError.fromMessageAndContext(`Auth0 logon redirect callback was presented with an AppState that did not contain our 'state' value that could be decrypted`, {appState: appState, user: user})

        }
        const  logonState = this.auth0LogonStateMap.get(state) as LogonStateSave

        if (!logonState || state !== logonState.originalString) {
            throw WorldliesError.fromMessageAndContext(`The state received from Auth0 does not match the one we have on record. Our record: '${logonState.originalString}'; Auth0 record: '${state}'`,
                {
                    params: {
                        appState: appState,
                        user: user
                    },
                    logonState: logonState,
                    encryptedStateFromAuth0: encrState,
                    encryptedStateOurEnd: logonState.encryptedString,
                    originalString: logonState.originalString,
                    decryptedFromAut0: state,
                })
        }
        const loginLandingPageSpec =  RouterManager.getRouterManager().getRouteSpecByName(RouteName.LoginLandingPage) as RouteSpec
        let url = createLocalAppURL(appState.returnTo ?? loginLandingPageSpec.contextRelativePath as string) as string
        appState.returnTo = url;
        this.doMaintenance()
            .finally(() => {
                console.debug()
            })
        return appState
    }

    getAuth0 = () => (this.auth0);

    getAuthToken = async () => {
        try {
            const token = await this.auth0.getAccessTokenSilently();
            if (this.clientSession) {
                this.clientSession.accessToken = token;
                this.clientSessionContext.setClientSession(this.clientSession)
            }
            return this.clientSession;
        } catch(e: any) {
            throw WorldliesError.fromError(e)
        }
    }

    getClientSession =  () => {
        return this.clientSession;
    }

    setClientSession = (clientSession: ClientSession) => {
        this.clientSession = clientSession;
        this.clientSessionContext.setClientSession(clientSession)
    }

    updatePasswordChangeStatus = (needsPasswordChange : boolean) => this.needPasswordChange = needsPasswordChange;
    updateEmailVerifiedStatus = (emailVerified: boolean) => {
          this.emailVerified = emailVerified;
           //this.user!.email_verified =  this.emailVerified;
    }

    onRedirectCallback = (appState?: AppState, user?: User) => {
        const msg = `AuthenticationService::onRedirectCallback(appState,user)` +
            (appState ? `${JSON.stringify(appState,null,3)}\n` : "") +
           (user ? `${JSON.stringify(user,null,3)}\n` : '');
        console.debug(msg)

        if (!appState) {
            throw WorldliesError.fromMessageAndContext(`onRedirectCallback expected an appState parameter`,{appState: appState, user: user})
        }
        const state = UuidUtils.gen()
        const appState2 = Object.assign({}, appState,{state: state})
        const {appState: finalAppState, user : finalUser} = this.processRedirection(appState2,user) as {appState: AppState, user: User}
        const url = finalAppState.returnTo as string
        if (this.navigate) {
            this.navigate(url)
        } else {
            throw WorldliesError.fromMessage(`AuthenticationService has not yet been set with a NavigationFunction and so it is now unable to to redirect the user to ${url}`)
        }

    }
    isLoading = () => this.auth0.isLoading;

    isAuthenticated = () => this.auth0.isAuthenticated;
    isUser = () => !!(this.auth0.user);
    getUser = () => this.auth0.user;
    isEmailVerified = () => (this.emailVerified);
    needsPasswordChange = () => (this.needPasswordChange);
    canAccess = () => ( (this.clientSession && this.clientSession.canAccess) || false);
    signUp = () => {
        const routerManager = RouterManager.getRouterManager()
        const redirectUrl = routerManager.getServerRelativePath(RouteName.SignUpLandingPage)
        let redirectLogonOptions: RedirectLoginOptions<AppState>;
        redirectLogonOptions = {
            appState: {
                returnTo: redirectUrl,

              //  display: 'popup',
                // state: undefined,
            },
            authorizationParams: {
                audience: AuthProviderConfig.authorizationParams.audience,
                screen_hint: 'signup'
            },


        } as RedirectLoginOptions<AppState>;
        const initedRediredtionOptions  = this.processPreLogin(redirectLogonOptions) as RedirectLoginOptions<AppState>
        const appState = initedRediredtionOptions.appState

       this.auth0.loginWithRedirect(initedRediredtionOptions)
        alert('doing sign-up from auth service')
    };
    loginWithRedirect = () => {
        const routerManager = RouterManager.getRouterManager()
        const redirectUrl = routerManager.getServerRelativePath(RouteName.LoginLandingPage)
            const redirectLogonOptions = {
            appState: {
                returnTo: redirectUrl,
               // display: 'popup',
                // state: undefined,
                authorizationParams: {
                    audience: AuthProviderConfig.authorizationParams.audience,
                    screen_hint: ''
                }
            }
        } as RedirectLoginOptions<AppState>
        const initRedirectionOptions = this.processPreLogin(redirectLogonOptions)
       this.auth0.loginWithRedirect(initRedirectionOptions)
        alert('doing login from auth service')
    };
    logOff = () => {
        const routerManager = RouterManager.getRouterManager()
        const redirectUrl = routerManager.getServerRelativePath(RouteName.LogoutLandingPage)
        const obj = {
            logoutParams: {
                returnTo: redirectUrl,
            }
        }
        this.auth0.logout(obj)
    }
    getAccountExists = async (): Promise<boolean> => {
        if (!(this.authToken && this.user && this.user.email)) {
            throw WorldliesError.fromMessage('Attempt to determine if Worldlies account exists before user authentication has completed')
        }
        const veifyEmailRequest = {
            email: this.user?.email,
            accessToken: this.authToken,
        } as VerifyEmailRequest;


        const asyncConfig = {
            signal: this.abortController.signal
        } as AsyncConfig
        try {
            const result = await this.securityService.checkAccountExists(veifyEmailRequest, asyncConfig);
            if (result && typeof result === 'boolean') {
                return true
            } else if (result && typeof result == 'object' && result.responseType && result.responseType === 'ErrorResponse') {
                throw WorldliesError.fromErrorResponse(result as ErrorResponse)
            } else {
                const msg = `Unexpected result type from API service 'getAccountExists' interface`;
                throw WorldliesError.fromMessageAndContext(msg, result, true);
            }
        } catch (error: any) {
            throw WorldliesError.fromError(error)
        }

    };
    createApiServerSession = async (user: User, accessToken: string): Promise<ClientSession> => {

        const userLoginRequest = {
            email: user.email,
            emailVerified: user.email_verified!,
            accessToken: accessToken,
            nickName: user.nickName,
            givenName: user.givenName,
            familyName: user.familyName,
        } as UserLoginRequest;
        try {
            const res = await this.securityService.authorize(userLoginRequest, {});
            if (res && 'objectTypeName' in res && res["objectTypeName"] === "ClientSession") {
                return res as ClientSession
            }
            const context = new Map<string,any>()
            context.set("PARAM_ACCESS_TOKEN",accessToken)
            context.set('PARAM_USER',user)
            context.set('API_REQUEST_OBJECT',userLoginRequest)
            context.set('API_RESULT_OBJECT',res)
            throw WorldliesError.fromMessageAndContext('createApiServerSession returned unexpected object type',context)
        } catch (error: any) {
            const msg = `An exception occurred on access of API service 'authorize' interface`
            throw WorldliesError.fromError(error, msg);
        }

    };
    registerAccount = async (fullName: string): Promise<ClientSession> => {
        if (!(this.authToken && this.user && this.user.email)) {
            throw WorldliesError.fromMessage('Attempt to determine if Worldlies account exists before user authentication has completed')
        }
        if (!this.user || !this.user.sub ) {
            throw WorldliesError.fromMessage(`Registration in the Worldlies system cannot be done until the user has authenticated:`)
        }

        const req = createAccountCreateRequest();

        req.accessToken = this.authToken;
        req.authProviderUserId = this.user.sub;
        req.loginEmail = this.user.email;
        req.openDateTime = new Date();
        req.userLocalDateTime = new Date();
        req.loginFullName = fullName;
        req.accountName = fullName as string;
        req.isoLanguage = 'en';
        req.isoCountry = 'us';
        try {
            const result = await this.securityService.registerAccount(req, {});
            if (result && result.responseType && result.responseType === 'AccountCreateResponse') {
                const o = result as AccountCreateResponse;
                const sc = createClientSession();
                sc.email = this.user.email;
                sc.accountId = o.accountId;
                sc.userId = o.userId;
                sc.auth0UserId = this.user?.sub!;
                sc.accessToken = this.authToken;
                sc.accountName = fullName;
                sc.nickName = this.user?.nickName;
                sc.userFullName = fullName;
                sc.accountName = sc.userFullName;
                sc.userFullName = this.user.nickName;
                sc.sessionId = o.sessionId;
                sc.canAccess = true;
                this.clientSession = sc;
                return sc
            } else if (result && result.responseType && result.responseType === 'ErrorResponse') {
                throw WorldliesError.fromErrorResponse(result as ErrorResponse);
            } else {
                const msg = `Unexpected result type from API service 'registerAccount' interface`;
                throw WorldliesError.fromMessageAndContext(msg, result, true);

            }
        } catch (e: any) {
            const msg = `An exception occurred on access of API service 'registerAccount' interface`
            throw WorldliesError.fromError(e, msg, true)

        }
    };
}
