
  export const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key: any, value: any) => {
            if (!key || !value) return null
            if (typeof value === "object") {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
             }
            return value;
        };
    };

