import * as yup from "yup";

import {IExtendableObjSchema} from "../../utils/extensible-obj-schema";
import {ObjectSchema} from "yup";
import {createPropertyList, updateObjectFromFormData} from "./form-validation-utils";
import {UseFormGetValues} from "react-hook-form";
import {Asset} from "../domain/worldlies-data-module";
import dayjs from "dayjs";

const urlRegEx  = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const usCurrencyRegEx =   /^\d+(?:\.\d{0,2})$/;  // /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
const usePhoneRegEx = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const usSocSecNumRegEx = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
const usStreetAddressRegEx = /^(\d{1,}) [a-zA-Z0-9\s]+(\,)? [a-zA-Z]+(\,)? [A-Z]{2} [0-9]{5,6}$/;
const usPostalCodeRegEx = /^[0-9]{5}(?:-[0-9]{4})?$/;
const usEmailRegEx = /^\S+@\S+\.\S+$/;
const usPasswordRegEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

/*  PASSWORD
Has minimum 8 characters in length. Adjust it by modifying {8,}
At least one uppercase English letter. You can remove this condition by removing (?=.*?[A-Z])
At least one lowercase English letter.  You can remove this condition by removing (?=.*?[a-z])
At least one digit. You can remove this condition by removing (?=.*?[0-9])
At least one special character,  You can remove this condition by removing (?=.*?[#?!@$%^&*-])
 */

yup.addMethod(yup.object, 'dayjs', function method(message) {
    return this.test('dayjs', message, function validate(value){
        if (!value) {
            return true
        }
        return dayjs.isDayjs(value)
    })
})


export const assetFormSchema = yup.object().shape({
    // GENERAL INFORMATION
    'friendlyName': yup.string().trim().nullable().required('The asset must be named').min(5, 'The name must be at least 5 characters long').max(50, 'The name must not be more than 20 characters long'),
    'placeId': yup.string().required("Need to know the place where the asset is located"),
    'categoryId': yup.string().nullable(),
    // MAKE/MODEL
    'make': yup.string().trim().max(20,'The make (manufacture/brand) cannot be more than 30 characters').nullable(),
    'modelName': yup.string().trim().max(20, 'The model Name cannot be more than 30 characters').nullable(),
    'modelNum': yup.string().trim().max(20, 'The model number cannot be more than 30 characters').nullable(),
    'serialNum': yup.string().trim().max(20, 'The serial number cannot be more than 30 characters').nullable(),
    // ACQUISITION (PURCHASE)
   //'acquisitionMechanism': yup.string().nullable(),
    'purchasedAt': yup.string().trim().max(20).nullable(),
    'purchaseDate': yup.date().nullable().max(dayjs().toDate(),"Date cannot be after today"),
    //'acquisitionWebSite': yup.string().matches(urlRegEx),
    //'acquisitionBarCode': yup.object(),
    'purchasePrice': yup.string().matches(usCurrencyRegEx,{message: "Only digits and the decimal point. If , nor more than two of which can be to the right of a single decimal point",excludeEmptyString: true}),
    'notes': yup.string().trim().max(10000).nullable(),


    // DISPOSAL (SALE)
    // 'disposalMechanism': yup.string().nullable(),
    // 'disposedTo': yup.string().trim().max(20).nullable(),
    // 'disposalDate': yup.date().nullable(),
    // 'disposalWebSite': yup.string().matches(urlRegEx),
    // 'disposalBarCode': yup.object(),
    // 'disposalPrice': yup.string().matches(usCurrencyRegEx),
    // INSURANCE
    // 'policyId': yup.string(),


});
const fields = assetFormSchema.fields
type AssetFormSchema = typeof assetFormSchema
export interface IAssetFormData extends yup.InferType<AssetFormSchema> {

}




export const createAssetFormPropertyList = () => createPropertyList(assetFormSchema,assetFormSchema);

export const updateAssetFromForm = (getValues: UseFormGetValues<any>, modelObj: Asset) => updateObjectFromFormData<AssetFormSchema,Asset>(getValues,assetFormSchema,modelObj);
