import {alpha, createTheme, responsiveFontSizes} from "@mui/material/styles";
import {grey, lightBlue, pink} from "@mui/material/colors";


const Black = '#000000';
// const White = '#FFFFFF';
const BUTTON_BACKGROUND_OPACITY = 0.5;
//const BUTTON_FOREGROUND_OPACITY = 0.8;
export const AppTheme = responsiveFontSizes(createTheme({

    palette: {
        primary: {
            light: lightBlue[200],
            main: lightBlue[500],
            dark: lightBlue[800],
            contrastText: Black,
        },
        secondary: {
            light: pink[200],
            main: pink[500],
            dark: pink[600],
            contrastText: Black,
        },
        text: {
            primary: Black,
            secondary: Black,
        }
    },
    // eslint-disable-next-line no-use-before-define
    typography: {
        htmlFontSize: 16,
        fontFamily: [
            "Roboto",
            "Quicksand",
            "Dosis",
            'Nunito',
            'Noto_Sans_Mono',
        ].join(','),

    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(lightBlue[500], BUTTON_BACKGROUND_OPACITY),
                    color: grey[900],
                    fontWeight: 'bold'
                }
            },
            defaultProps: {}

        },

    },

}));
