import {ErrorResponse, HttpStatus} from "../models/domain/worldlies-data-module";
import {getCircularReplacer} from "./json-utils";


export class ErrorUtils {
    private static readonly USER_MESSAGE = "An unspecified error occurred";
    private static readonly UNKNOWN_STATUS = "UNKNOWN STATUS";
    private static readonly NO_MESSAGE = "NO MESSAGE";
    private static readonly NO_ERROR_ID = "";


    static createErrorResponse = (message: string, contextObj: any, logIt = false) : ErrorResponse => {
        let o =  {
                responseType: "ErrorResponse",
                success: false,
                status: "BAD_REQUEST",
                apiException: true,
                timestamp: new Date(),
                errorId: ErrorUtils.NO_ERROR_ID,
                message: ErrorUtils.USER_MESSAGE,
                debugMessage: message,
                subErrors: [],
                cause: null,
                contextMap: new Map<string,object>(),
                sourceComponentName: "",
            } as ErrorResponse;

        if (contextObj) {
            if ('responseType' in contextObj) {
                o = contextObj;
            } else if (typeof contextObj === "string") {
                o.debugMessage = contextObj;
            } else {
                let errorObj: Error | undefined = undefined;
                if (contextObj instanceof Error) {
                    errorObj = contextObj as Error;
                }
                if ('errorObj' in contextObj) {
                    errorObj = contextObj['errorObj'];
                }
                if (errorObj) {
                    if (message != ErrorUtils.NO_MESSAGE) {
                        o.debugMessage = errorObj.message;
                    }
                    if (o.errorId == ErrorUtils.NO_ERROR_ID) {
                        o.errorId = contextObj.name;
                    }
                }
                o.cause = JSON.stringify(contextObj, null, 2);
            }
        }
        if(logIt) {
            this.logErrorResponse(o);
        }
        return o;
    }
    static createFromError = (errorObj: any, message = ErrorUtils.NO_MESSAGE, logIt= false): ErrorResponse => {
        if (errorObj && errorObj instanceof Error && errorObj.message && message == ErrorUtils.NO_MESSAGE) {
            message = errorObj.message;
        }
        return ErrorUtils.createErrorResponse(message,errorObj,logIt);

    }

    static createFromErrorWithContext = (errorObj: any, contextObj: any = null, message = ErrorUtils.NO_MESSAGE, logIt= false): ErrorResponse => {
        if (errorObj && errorObj instanceof Error && errorObj.message && message == ErrorUtils.NO_MESSAGE) {
            message = errorObj.message;
        }
        const obj = (contextObj ? {errorObj: errorObj, contextObj: contextObj} : errorObj);
        return ErrorUtils.createErrorResponse(message,obj,logIt);

    }

    static createErrorResponseString = (errorResponse: ErrorResponse) => {
        const s = `[Error:\n
                    \tID: ${errorResponse.errorId}\n
                    \tTime: ${errorResponse.timestamp}\n
                    \tWas client-side Exception: ${errorResponse.apiException}\n
                    \tStatus: ${errorResponse.status}\n
                    \tMessage: ${errorResponse.message}\n
                    \tInternal message: ${errorResponse.debugMessage}\n
                    ]\n`;
        const t = JSON.stringify(errorResponse,getCircularReplacer(),2);
        return t
    }
    static logErrorResponse = (errorResponse: ErrorResponse) => {
        const s = this.createErrorResponseString(errorResponse)
        console.error(s);
    }

}
