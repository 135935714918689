import React, {createRef, RefObject, useDeferredValue, useEffect, useRef, useState} from 'react';
import {
    GridCallbackDetails,
    GridColDef,
    GridEventListener,
    GridRowParams,
    GridRowSelectionModel,
    GridToolbarContainer,
    MuiEvent,
    useGridApiRef,
} from '@mui/x-data-grid';
import dayjs from "dayjs";
import './asset-panel.css';
import {Asset, ClientSession} from '../../../models/domain/worldlies-data-module'
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Alert, Box, Button, IconButton, Stack} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';

import CloseIcon from "@mui/icons-material/Close";
import {AssetEditorPanel} from "../../../components/asset-editor-panel";
import {WDataGrid} from "../../../components/widgets/w-data-grid";
import {useWindowSize} from "@react-hook/window-size";
import {PlaceNode} from "../../../models/container/place-node";
import {createAssetNode} from "../../../models/container/asset-node"
import stringify from "json-stringify-safe";
import {WorldliesError} from "../../../models/validation/worldlies-error";
import {getServiceBroker, ServiceType} from "../../../services/service-broker-types";
import {IPlaceAssetService} from "../../../services/data-service-types";
import {IAuthService} from "../../../services/security-service-types";
import {useAuth0} from "@auth0/auth0-react";
import {ParentPageContext} from "../../../models/reference/parent-page-context";



interface IButtonEnableMap {
    refresh: boolean,
    edit: boolean,
    new: boolean,
}

interface IAssetGridToolbarProps {
    refreshCallback: () => void,
    editCallback: () => void,
    newCallback: () => void,
    initialEnableMap: IButtonEnableMap,
    ref: RefObject<React.Component>,

}


class AssetGridToolbar extends React.Component<IAssetGridToolbarProps> {
    private static readonly DEFAULT_ENABLE_MAP: IButtonEnableMap = {refresh: true, edit: false, new: true};
    private refreshCallback: () => void;
    private editCallback: () => void;
    private newCallback: () => void;
    private enableMap: IButtonEnableMap = AssetGridToolbar.DEFAULT_ENABLE_MAP;
// private ref: RefObject<React.Component>;
    private refreshRef: RefObject<HTMLButtonElement>;
    private editRef: RefObject<HTMLButtonElement>;
    private newRef: RefObject<HTMLButtonElement>;
    private button_size = "small"

    constructor(props: IAssetGridToolbarProps) {
        super(props);
        this.refreshCallback = props.refreshCallback;
        this.editCallback = props.editCallback;
        this.newCallback = props.newCallback;
        this.enableMap = props.initialEnableMap || AssetGridToolbar.DEFAULT_ENABLE_MAP;
        // this.ref = props.ref;
        this.refreshRef = createRef<HTMLButtonElement>();
        this.editRef = createRef<HTMLButtonElement>();
        this.newRef = createRef<HTMLButtonElement>();
        console.log("Component: AssetGridToolbar") // TRACE;
    }

    public updateEnabledState(enableMap: IButtonEnableMap) {
        if (enableMap && this.enableMap && this.refreshRef.current && this.newRef.current && this.editRef.current) {
            if (this.enableMap.refresh !== enableMap.refresh) {
                this.refreshRef.current.disabled = enableMap.refresh;
            }
            if (this.enableMap.new !== enableMap.new) {
                this.newRef.current.disabled = enableMap.new;
            }
            if (this.enableMap.edit !== enableMap.edit) {
                this.editRef.current.disabled = enableMap.edit;
            }
        }
        this.enableMap = enableMap;
        this.forceUpdate()
    }

    render() {
        return (
            <GridToolbarContainer>

                {/*<Stack direction={'row'} id="AssetPanelButtonBar" className={"ButtonBar"} spacing={"10px"}*/}
                {/*       sx={{paddingLeft: "5px"}}>*/}
                <Button id="XNewAssetButton" variant={"contained"} onClick={this.newCallback} size={"small"}
                        disabled={!this.enableMap.new} ref={this.newRef} sx={{width: 100}} startIcon={<AddIcon/>}
                >New</Button>
                <Button id="XEditAssetButton" variant={"contained"} onClick={this.editCallback} size={"small"}
                        disabled={!this.enableMap.edit} ref={this.editRef} sx={{width: 100, marginLeft: "10px"}}
                        startIcon={<EditIcon/>}
                >Edit</Button>
                <Button id="XRefreshButton" variant={"contained"} onClick={this.refreshCallback} size={"small"}
                        disabled={!this.enableMap.refresh} ref={this.refreshRef} sx={{width: 100, marginLeft: "10px"}}
                        startIcon={<SyncIcon/>}
                >Refresh</Button>
                {/*</Stack>*/}

            </GridToolbarContainer>
        )
    }
}

export const AssetPanel: React.FunctionComponent = () => {

        console.log("Component: AssetPanel") // TRACE;

    const navigate = useNavigate()
    const serviceBroker = getServiceBroker()
    const authService = serviceBroker.getService(ServiceType.Authentication) as IAuthService
    const {user, isAuthenticated,isLoading,loginWithRedirect} = useAuth0()
    const {error: auth0_error, code, error_description: auth0_error_description, state: auth0_state} = useParams();
    const pageContext = useOutletContext<ParentPageContext>()





        const [width, height] = useWindowSize();
        // const theme = useTheme();

        const apiRef = useGridApiRef()


        // const authServiceContext = useAuthServiceContext();
        const placeAssetServiceContext = serviceBroker.getService(ServiceType.PlaceAsset) as IPlaceAssetService;


        const [clientSession, setClientSession] = useState<ClientSession | undefined>(undefined);
        const [hasAccess, setHasAccess] = useState<boolean | undefined>(undefined);

        const [worldliesError, setWorldliesError] = useState<WorldliesError | undefined>();
        const [rowData, setRowData] = useState<Asset[]>([]);
        const deferredRowData = useDeferredValue(rowData)

        // REMOVE const [preOpen, setPreOpen] =  useState<boolean>(false);
        const [open, setOpen] = useState<boolean>(false);
        const [curAsset, setCurAsset] = useState<Asset | undefined>(undefined);
        const [editingAsset, setEditingAsset] = useState<Asset | undefined>(undefined);

        const [selectedIds, setSelectedIds] = useState<Array<string>>([]);


        const gridToolbarRef = useRef<AssetGridToolbar>(null)

        const [displayError, setDisplayError] = useState<boolean>(false)

        useEffect(() => {
            if (authService && placeAssetServiceContext && !!!hasAccess) {

                const cs = authService.getClientSession()
                setClientSession(cs);
                setHasAccess(cs?.canAccess)



            }

        }, [authService,placeAssetServiceContext]);

        useEffect(() => {
            if (clientSession) {
                loadData(true)
                    .then(_ => {

                    })
                    .catch(e => {
                        const worldliesError = WorldliesError.fromError(e);
                        worldliesError.contextMap.set('AssetPanel-useEffect','Failed trying to load data from ')
                        setWorldliesError(worldliesError)
                    })
            }

        }, [clientSession]);


        useEffect(() => {
            if (worldliesError) {

                setDisplayError(true)
            }
        }, [worldliesError]);


        // useEffect(() => {
        //     if (selectedIds && selectedIds.length > 0) {
        //         //setEditingAsset(curAsset);
        //     } else {
        //         // setEditingAsset(undefined);
        //     }
        // }, [selectedIds])

        useEffect(() => {
            if (editingAsset) {
                setOpen(true);
            }

        }, [editingAsset])


        function dateFormatter(params: any) {
            if (!params.value) {
                return "";
            }
            return dayjs(params.value).format('MMM, D, YYYY');
        }

        const columnDefs: GridColDef[] = [

            {field: 'friendlyName', headerName: 'Name', width: 150, type: 'string'},
            {field: 'placeName', headerName: 'Place', width: 150, type: 'string'},
            {field: 'categoryName', headerName: 'Category', width: 150, type: 'string'},
            {field: 'purchasePrice', headerName: 'Price', width: 150, type: 'number'},
            {field: 'purchaseDate', headerName: 'Purchase Date', width: 150, type: 'date', valueFormatter: dateFormatter},
            {field: 'make', headerName: 'Make', width: 150, type: 'string'},
            {field: 'modelName', headerName: 'Model', width: 150, type: 'string'},
            {field: 'serialNum', headerName: 'Serial Num', width: 150, type: 'string'},
        ];

        //  DefaultColDef sets props common to all Columns
        // const defaultColDef = useMemo(() => ({
        //     sortable: true
        // }), columnDefs);

        const onRowsSelectionHandler = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
            let selectedAsset: Asset | undefined = undefined;
            if (rowSelectionModel && rowSelectionModel.length > 0 && placeAssetServiceContext) {
                const ids = Array.from(rowSelectionModel.values()) as string[];
                setSelectedIds(ids);
                selectedAsset = placeAssetServiceContext.getAssetById(ids[0]);
                if (selectedAsset) {
                    setCurAsset(selectedAsset);
                }
                console.debug(`Selection change with details ${details.reason} with resultant selected IDs:\t ${ids.join(',\n\t- ')}`);
            } else {
                setSelectedIds([]);
                setCurAsset(undefined);

            }
            updateGridToolbar({refresh: true, new: true, edit: !!selectedAsset});

        }

        const updateGridToolbar = (enableMap: IButtonEnableMap) => {
            if (gridToolbarRef.current) {
                gridToolbarRef.current.updateEnabledState(enableMap)
            }
        }

        const onRowDoubleClick: GridEventListener<'rowDoubleClick'> = (
            params: GridRowParams,
            event: MuiEvent<React.MouseEvent<HTMLElement>>,
            details: GridCallbackDetails) => {

            params.id as string;
            const ids = [params.id as string];
            let selectedAsset: Asset | undefined = undefined;
            if (selectedIds.length != 1 || selectedIds[0] != ids[0]) {
                setSelectedIds(ids)
                selectedAsset = placeAssetServiceContext?.getAssetById(ids[0]);
                if (selectedAsset) {
                    setCurAsset(selectedAsset);

                }
            }
            updateGridToolbar({refresh: true, new: true, edit: !!selectedAsset});
            setEditingAsset(curAsset);

        }

        const createPlacePath = (place: PlaceNode): string[] => {
            const list = Array<string>()
            let p: PlaceNode | undefined = place;
            while (p) {
                list.push(p.id)
                if (p.parentId) {
                    p = placeAssetServiceContext?.getPlaceById(p.parentId)
                }
            }
            return list.reverse()

        }

        const loadData = async (serverRefresh: boolean)  => {
            if (placeAssetServiceContext &&
                (serverRefresh || placeAssetServiceContext.getAssets().length == 0)) {
                placeAssetServiceContext.load(serverRefresh)
                    .then(_ => {
                        if (placeAssetServiceContext.getAssets().length > 0) {
                            setRowData(placeAssetServiceContext.getAssets())
                        }
                    })
                    .catch(e => {
                        const err = WorldliesError.fromError(e);
                        setWorldliesError(err);
                    })
            }
        }
        const onRefresh = () => {
            loadData(true)
        }

        const onEditAsset = () => {
            console.log(`Opening asset editor for asset id ${curAsset?.id} `)
            setEditingAsset(curAsset);
        }

        const onCreateAssetNode = () => {
            setEditingAsset(createAssetNode());
        }

        const cancelAssetEditor = () => {
            setEditingAsset(undefined);
            setOpen(false);

        }

        const saveAssetEditor = async (asset: Asset) => {

            try {
                let newRowData: Asset[] = []
                if (placeAssetServiceContext && clientSession) {
                    setEditingAsset(undefined);
                    setOpen(false);
                    const existingAsset = placeAssetServiceContext.getAssetById(asset.id);
                    if (existingAsset) {
                        const updatedAsset = {
                            ...existingAsset,
                            ...asset
                        }
                        placeAssetServiceContext.updateAsset(updatedAsset);

                    } else {
                        placeAssetServiceContext.addAsset(asset);
                    }
                    const result = placeAssetServiceContext.commitAllChanges()
                    if (result && typeof result == 'boolean' && (result as boolean) === true) {
                        const strAsset = JSON.stringify(asset, null, 2);
                        console.log(`Saved asset: ${strAsset}`);
                        await placeAssetServiceContext.load(true);
                        if (placeAssetServiceContext.getAssets().length > 0) {
                            newRowData = placeAssetServiceContext.getAssets();
                            setRowData(placeAssetServiceContext.getAssets())
                        }

                    }

                }

            } catch (e: any) {
               throw WorldliesError.fromError(e)
            }


        }

        const showError = () => {
            if (worldliesError) {
                return (
                    <Alert
                        id={'asset-panel-error-alert'}
                        variant="filled"
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setDisplayError(false)
                                    setWorldliesError(undefined);

                                }}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                        sx={{mb: 2}}
                    >
                        <Stack direction={'column'}>
                            <Box>{worldliesError.message}</Box>
                            <Box>{stringify(worldliesError, null, 2)}</Box>
                        </Stack>
                        {worldliesError.message}
                    </Alert>
                );
            } else {
                return null;
            }
        }


        const gridToolbarProps = {
            refreshCallback: onRefresh,
            editCallback: onEditAsset,
            newCallback: onCreateAssetNode,
            initialEnableMap: {refresh: true, edit: !curAsset, new: true},
            ref: gridToolbarRef,
        } as IAssetGridToolbarProps

        if (!placeAssetServiceContext ) {
            return null;   //    <div></div>
        } else {
            return (
                <div id="AssetPanel" className={"AssetPanel"}>
                    <div>
                        {open ?
                            <AssetEditorPanel open={open} handleClose={cancelAssetEditor} handleSave={saveAssetEditor}
                                              asset={editingAsset}
                                              width={width}
                                              height={height}


                            />
                            : null}
                        {displayError ?
                            showError()
                            : null}

                    </div>

                    <div id="GridContainer" className="ag-theme-alpine">
                        <WDataGrid
                            apiRef={apiRef}
                            className={"AssetGrid"}
                            rows={deferredRowData}
                            columns={columnDefs}

                            density={"compact"}
                            autoHeight={true}
                            initialState={{
                                pagination: {
                                    paginationModel: {pageSize: 25, page: 0},
                                },
                            }}


                            //checkboxSelection
                            // ssssss disableMultipleRowSelection
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            onRowDoubleClick={onRowDoubleClick}
                            onRowSelectionModelChange={onRowsSelectionHandler}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                            }}
                            slots={{
                                toolbar: AssetGridToolbar,
                            }}
                            slotProps={{
                                toolbar: gridToolbarProps,
                            }}

                        />

                    </div>
                </div>

            );
        }


    }
;


