import {ErrorResponse, HttpStatus, ValidationError} from "../domain/worldlies-data-module";
import {UuidUtils} from "../../utils/uuid-utils";

const stringify = require('json-stringify-safe');

export interface ErrorNote {
    timestamp: Date,
    note: string,
    location?: string,
}

const MARKER = 'WorldliesError'
const ORIGINAL_ERROR_OBJ_TYPE_KEY = "ORIGINAL_ERROR_OBJ_TYPE";
const ORIGINAL_ERROR_OBJ = "ORIGINAL_ERROR_OBJ";
const CONTEXT_OBJ_KEY = "CONTEXT_OBJ"

export class WorldliesError extends Error implements WorldliesError {


    marker: string = MARKER
    success: boolean = false;
    responseType: string = 'ErrorResponse';
    timestamp: Date = new Date();
    status: HttpStatus = "BAD_REQUEST";
    errorId: string = UuidUtils.gen();
    // message: string = "";
    debugMessage: string = "Client-side Exception";
    subErrors: ValidationError[] = [];
    cause: any = null;
    apiException: boolean = false;
    contextMap = new Map<string, any>();
    sourceComponentName: string = "";
    errorNotes: ErrorNote[] = [];
    numAdditionalMessages: number = 0;


    private constructor(obj?: any) {
        super(WorldliesError.extractMessage(obj));

        try {
            if (obj) {

                Object.keys(this).forEach(key => {
                    if (obj[key as keyof typeof obj] !== undefined) {
                        this[key as keyof typeof this] = obj[key as keyof typeof obj]
                    }
                })

            }

        } catch (e: any) {
            console.error('exception in WorldliesError constructor:')
            const errStr = stringify(e,null,2);
            console.error(errStr);
        }
        this.name = MARKER
        Object.setPrototypeOf(this, new.target.prototype);
    }

    public addSourceComponentName = (addedSourceComponentName: string) => {
        let scn = this.sourceComponentName
        scn = scn ? `${addedSourceComponentName} -> ${scn}` : `SecurityServiceApi:registerAccount`
        this.sourceComponentName =  scn
}

    public toErrorResponse(): WorldliesError {
        return  {
            success: this.success,
            responseType: this.responseType,
            timestamp: this.timestamp,
            status: this.status,
            errorId: this.errorId,
            message: this.message,
            debugMessage: this.debugMessage,
            subErrors: this.subErrors,
            cause: this.cause,
            apiException: this.apiException,
        } as WorldliesError;

    }

    public addErrorNote(note: string, location?: string) {
        this.errorNotes.push({timestamp: new Date(), note: note, location: location});
    }

    private static extractMessage(obj: any): string {
        // obj instanceof Error ? (obj as Error).message : (typeof obj === 'string' ? (obj as string) : ('responseType' in obj && obj["responseType"] == 'ErrorResponse' ? (obj as ErrorResponse).message))
        if (obj instanceof Error) {
            return obj.message;
        } else if (typeof obj === 'string') {
            return obj as string
        } else if ('message' in obj && typeof obj["message"] == 'string') {
            return obj["message"];
        } else {
            return `Initialized with unknown type with properties: ${JSON.stringify(obj)}`;
        }
    }

    public static fromMessageAndContext(message: string, contextObj: any, logIt = false): WorldliesError {
        const e = new WorldliesError(message);
        e.contextMap.set(ORIGINAL_ERROR_OBJ_TYPE_KEY, 'string')
        e.contextMap.set(CONTEXT_OBJ_KEY, contextObj)
        if (logIt) WorldliesError.logError(e)
        return e
    }

    public static fromMessage(message: string, logIt = false) {
        const e = new WorldliesError(message);
        e.contextMap.set(ORIGINAL_ERROR_OBJ_TYPE_KEY, 'string')
        if (logIt) WorldliesError.logError(e)
        return e
    }

    public static fromError(error: Error, addedMessage: string | null = null, contextObj: any = null, logIt = false): WorldliesError {
        const e: WorldliesError = (error instanceof WorldliesError) ? error as WorldliesError : new WorldliesError(error)

        e.contextMap.set(ORIGINAL_ERROR_OBJ_TYPE_KEY, error.constructor.name)
        e.contextMap.set(ORIGINAL_ERROR_OBJ, error)
        if (contextObj) {
            e.contextMap.set(CONTEXT_OBJ_KEY, contextObj)
        }
        if (addedMessage) {

            e.numAdditionalMessages += 1;
            const key = `ADDITIONAL_MESSAGE-${e.numAdditionalMessages}`
            e.contextMap.set(key, addedMessage);
        }
        if (logIt) WorldliesError.logError(e)
        return e

    }

    public static fromErrorResponse(errorResponse: ErrorResponse, addedMessage: string | null = null, contextObj: any = null, logIt = false): WorldliesError {
        const e = new WorldliesError(errorResponse);
        e.contextMap.set(ORIGINAL_ERROR_OBJ_TYPE_KEY, 'ErrorResponse')
        if (contextObj) {
            e.contextMap.set(CONTEXT_OBJ_KEY, contextObj)
        }
        if (addedMessage) {

            e.numAdditionalMessages += 1;
            const key = `ADDITIONAL_MESSAGE-${e.numAdditionalMessages}`
            e.contextMap.set(key, addedMessage);
        }
        if (logIt) WorldliesError.logError(e)
        return e
    }

    // public static from(obj: any, contextObj: any = null): WorldliesError {
    //     return this.internalCreateFrom(obj,contextObj)
    // }
    // private static internalCreateFrom(obj: any = null, contextObj: any = null): WorldliesError {
    //     const ORIGINAL_ERROR_OBJ_TYPE_KEY = "ORIGINAL_ERROR_OBJ_TYPE";
    //     let strOrigErrorObjType = obj.constructor.name
    //     let returnObj: WorldliesError | null = null
    //     if (!obj) {
    //         returnObj = new WorldliesError()
    //     }
    //     if (obj instanceof WorldliesError) {
    //         returnObj = obj as WorldliesError;
    //     } else {
    //         returnObj = new WorldliesError(obj)
    //     }
    //     returnObj.contextMap.set(ORIGINAL_ERROR_OBJ_TYPE_KEY, strOrigErrorObjType)
    //     if (contextObj) {
    //         for (const prop in contextObj) {
    //             returnObj.contextMap.set(prop, contextObj.get(prop))
    //         }
    //     }
    //     return returnObj
    // }

    static stringifyError = (error: WorldliesError) => {
        return stringify(error, null, 2)
    }
    static logError = (error: WorldliesError) => {
        console.error(WorldliesError.stringifyError(error));
    }

}
