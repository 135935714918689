import {IExtendableObjSchema} from "../../utils/extensible-obj-schema";
import {ObjectSchema, } from "yup";
import * as yup from "yup";
import {createAssetFormPropertyList} from "./asset-validation";
import {UseFormGetValues} from "react-hook-form";
import {EntityBase} from "../domain/worldlies-data-module";
import dayjs from "dayjs"

yup.addMethod(yup.object, 'dayjs', function method(message) {
    return this.test('dayjs', message, function validate(value){
        if (!value) {
            return true
        }
        return dayjs.isDayjs(value)
    })
})

//const urlRegEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
//const usCurrencyRegEx = /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
//const usPhoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;


export const createValueMap = (schema: any, modelObj: any) : any => {
    if ('_nodes' in schema) {
        const props = schema._nodes;
        const resultObj = {} as IExtendableObjSchema;
        Object.entries(modelObj).forEach(([key, value]) => {
            if (props.includes(key)) {
                resultObj[key] = modelObj[key];
            }
        });

        return resultObj;
    } else {
        return {};
    }

}




export const createPropertyList = (schema: any, modelObj: any) : string[] => {

    if ('_nodes' in schema) {
        const props = schema._nodes;
        const propArray: string[] = [];
        Object.entries(modelObj).forEach(([key, value]) => {
            if (props.includes(key)) {
                propArray.push(key);
            }
        })
        return propArray;
    } else {
        return [];
    }
}

export const updateObjectFromFormData  = <TSchemaType, TModelObjType> (getValues: UseFormGetValues<any>, schema: any, modelObj: TModelObjType) : TModelObjType  => {

    const o = {
        ...modelObj
    }
    createPropertyList(schema,modelObj).forEach( p => {
        const v = (getValues() as IExtendableObjSchema)[p];
        // undefined values arise from disabled fields and are thus ignored
        if (v) {
            (o as IExtendableObjSchema)[p] = v;
        }

    })
    return o;
}
