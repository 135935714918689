import {alpha, styled} from '@mui/material/styles';
import {
    DataGrid,
    gridClasses,

} from '@mui/x-data-grid';



const SELECTED_OPACITY = 0.4;
const HOVER_OPACITY = 0.7;


export const WDataGrid = styled(DataGrid)(({theme}) => ({



    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: theme.palette.grey[50],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, HOVER_OPACITY),
            '@media (hover: none)': {
                backgroundColor: theme.palette.grey[50],
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.main,SELECTED_OPACITY),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    HOVER_OPACITY ,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor:alpha(theme.palette.primary.main,SELECTED_OPACITY),
                },
            },
        },
    },

    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, HOVER_OPACITY),
            '@media (hover: none)': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.main,SELECTED_OPACITY),
                '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                                        HOVER_OPACITY,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(theme.palette.primary.main,SELECTED_OPACITY),
                        },
            },
        },
    },
}));


